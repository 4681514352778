import React, { ChangeEvent, useState } from 'react';
import Row from '../shared/Row';

export type SortOption = 'endDateDesc' | 'startDateAsc' | 'startDateDesc' | 'starsDesc';

export const sortByEndDateDesc: SortOption = 'endDateDesc';
export const sortByStartDateAsc: SortOption = 'startDateAsc';
export const sortByStartDateDesc: SortOption = 'startDateDesc';
export const sortByStarsDesc: SortOption = 'starsDesc';

const IS_ENABLED_FILTER_BY_TAGS = false;

type Sorter = {
  title: string,
  hidden?: boolean,
}

type Sorters = Record<SortOption, Sorter>;

const sorters: Sorters = {
  [sortByEndDateDesc]: {
    title: '➘ end date',
  },
  [sortByStartDateDesc]: {
    title: '➘ start date',
  },
  [sortByStartDateAsc]: {
    title: '➚ start date',
    hidden: true,
  },
  [sortByStarsDesc]: {
    title: '➘ stars',
    hidden: true,
  },
};

export const supportedSortOptions: SortOption[] = Object
  .keys(sorters)
  // @ts-ignore
  .filter((sortOption: SortOption) => !sorters[sortOption].hidden);

type ProjectFiltersProps = {
  sortBy: SortOption,
  onSort: (sortKey: SortOption) => void,
  filterByTags: Array<string>,
  onFilterByTags: (filterByTags: Array<string>) => void,
};

const parseFilterByTagsFromString = (value: string): Array<string> => value.toLowerCase().split(',').filter(Boolean).map((el) => el.trim());

const ProjectFilters = (props: ProjectFiltersProps): React.ReactElement => {
  const {
    onSort,
    sortBy,
    filterByTags,
    onFilterByTags,
  } = props;

  const [filterByTagsInputValue, setFilterByTagsInputValue] = useState(filterByTags?.join(', ') || '');

  const sorterOptions = Object.keys(sorters)
    .filter((sorterKey: string) => {
      const sorter = sorters[sorterKey as SortOption];
      return !sorter?.hidden;
    })
    .map((sorterKey: string) => {
      const sorter = sorters[sorterKey as SortOption];
      return (
        <option key={sorterKey} value={sorterKey}>
          {sorter.title}
        </option>
      );
    });

  const onSortChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const selectedKey: SortOption = event.target.value as SortOption;
    onSort(selectedKey);
  };

  // useEffect(() => {
  //   if ()
  // }, [filterByTags]);

  return (
    <div>
      <Row>
        <div className="text-sm text-gray-500 mr-1">
          Sort by:
        </div>
        <div>
          <select onChange={onSortChange} defaultValue={sortBy} className="text-sm">
            {sorterOptions}
          </select>
        </div>
      </Row>
      {
        IS_ENABLED_FILTER_BY_TAGS ? (
          <Row>
            <div className="text-sm text-gray-500 mr-1">
              Filter:
            </div>
            <div>
              <input
                type="text"
                value={filterByTagsInputValue}
                onChange={(e): void => {
                  setFilterByTagsInputValue(e.target.value);
                  onFilterByTags(parseFilterByTagsFromString(e.target.value));
                }}
              />
            </div>
          </Row>
        ) : null
      }
    </div>
  );
};

export default ProjectFilters;
