import React, { useEffect, useState } from 'react';

import { Project as ProjectType, Projects as ProjectsType } from '../../types/Project';
import ProjectsList from '../elements/ProjectsList';
import PageLayout from '../layouts/PageLayout';
import PageHeader from '../shared/PageHeader';
import Badge from '../shared/Badge';
import Row from '../shared/Row';
import SEO from '../shared/SEO';
import ProjectFilters, {
  sortByEndDateDesc, sortByStarsDesc, sortByStartDateAsc, sortByStartDateDesc,
  SortOption, supportedSortOptions,
} from '../elements/ProjectFilters';
import {
  getGitHubProjectStars,
  // getTotalGetHubProjectStars,
  projectMapToArray,
} from '../../utils/project';
// import Stars from '../shared/Stars';
import { deleteSearchParam, getStringSearchParam, setSearchParam } from '../../utils/url';

type ProjectsScreenProps = {
  projects: ProjectsType,
};

type ProjectSorter = {
  // Returns -1, 0, +1.
  sort: (a: ProjectType, b: ProjectType) => number,
};

type ProjectSorters = Record<SortOption, ProjectSorter>;

/** URL option name for sort */
const SORT_PARAM_NAME = 'sort';
/** URL option name for filter by tags */
const FILTER_TAGS_PARAM_NAME = 'filter_tags';

// @ts-ignore
const projectSorters: ProjectSorters = {
  [sortByEndDateDesc]: {
    sort: (a: ProjectType, b: ProjectType): number => {
      if (!a?.endDate || !b.endDate || a.endDate === b.endDate) {
        return 0;
      }
      return a.endDate > b.endDate ? -1 : 1;
    },
  },
  [sortByStartDateDesc]: {
    sort: (a: ProjectType, b: ProjectType): number => {
      if (!a?.startDate || !b.startDate || a.startDate === b.startDate) {
        return 0;
      }
      return a.startDate > b.startDate ? -1 : 1;
    },
  },
  [sortByStartDateAsc]: {
    sort: (a: ProjectType, b: ProjectType): number => {
      if (!a?.startDate || !b.startDate || a.startDate === b.startDate) {
        return 0;
      }
      return a.startDate < b.startDate ? -1 : 1;
    },
  },
  [sortByStarsDesc]: {
    sort: (a: ProjectType, b: ProjectType): number => {
      const aStars = getGitHubProjectStars(a) || 0;
      const bStars = getGitHubProjectStars(b) || 0;
      if (aStars === bStars) {
        return 0;
      }
      return aStars > bStars ? -1 : 1;
    },
  },
};

/** gets the default sort option by parsing it from the url */
const getDefaultSortOption = (): SortOption => {
  const defaultOption = sortByEndDateDesc;
  // @ts-ignore
  const sortFromURL: SortOption = getStringSearchParam(SORT_PARAM_NAME, defaultOption);
  if (supportedSortOptions.includes(sortFromURL)) {
    return sortFromURL;
  }
  return defaultOption;
};

/** gets the default filter by tags option by parsing it from the url */
const getDefaultFilterByTags = (): Array<string> => {
  /** parse filterTagsFromURL as a string. eg: 'web,ios,android' */
  const filterTagsFromURL: string = getStringSearchParam(FILTER_TAGS_PARAM_NAME, '');
  /** if filterTagsFromURL is not an empty string -> parse into an array of tags - otherwise return an empty array to avoid returning an empty tag */
  return filterTagsFromURL ? filterTagsFromURL.split(',') : [];
};

const ProjectsScreen = (props: ProjectsScreenProps): React.ReactElement => {
  const { projects } = props;

  /** sort state, has a sort option to sort projects with */
  const [sortBy, setSortBy] = useState<SortOption>(getDefaultSortOption());
  /** filtered by tags state, has an array of strings that we should filter project tags with */
  const [filterByTags, setFilterByTags] = useState<Array<string>>(getDefaultFilterByTags());
  /** filtered projects to display, defaults to whichever filters parsed from the URL */
  const [filteredProjects, setFilteredProjects] = useState<ProjectType[]>(
    projectMapToArray(projects),
  );

  /** sort change handler */
  const onSort = (newSortOption: SortOption): void => {
    setSearchParam(SORT_PARAM_NAME, newSortOption);
    setSortBy(newSortOption);
  };

  /** filter by tags change handler */
  const onFilterByTags = (newFilterByTags: Array<string>): void => {
    /* set filter by tags state to the new value */
    setFilterByTags(newFilterByTags);
    /* set filter by tags search param in URL to the new value */
    if (newFilterByTags.length) {
      setSearchParam(FILTER_TAGS_PARAM_NAME, newFilterByTags.toString());
    } else {
      /* delete filter by tags search param in URL if the new value is empty */
      deleteSearchParam(FILTER_TAGS_PARAM_NAME);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    /** filtered list of projects to display */
    let projectsToDisplay = projectMapToArray(projects);
    // handle filter by tags if provided
    if (filterByTags.length) {
      /** Filter projects array by looping over one by one and finding matches */
      projectsToDisplay = projectsToDisplay.filter((project) => {
        /** get current project tags, loop over the array of objects and generate an array of strings from name */
        const projectTags = project.tags?.map((el) => el?.name?.toLowerCase()).filter(Boolean) || [];

        /** find all matches between projectTags */
        const matches = projectTags.filter((projectTag) => Boolean(projectTag && filterByTags.filter(
          /* loop over filter by tag values, trim the value to remove spaces, convert to lowercase, check if the current project tag matches ANY of the filter by tag values */
          (filterByTagValue) => filterByTagValue && projectTag.trim().toLowerCase().match(filterByTagValue),
        ).length));

        /* return whether any matches were found, if true then the current project is included, otherwise the project is skipped */
        return matches.length > 0;
      });
    }
    // handler sort
    projectsToDisplay = projectsToDisplay.sort(projectSorters[sortBy].sort);
    // update state
    setFilteredProjects(projectsToDisplay);
  }, [sortBy, filterByTags]);

  return (
    <PageLayout>
      <SEO
        title="Projects"
        description="Projects and experiments that help people learn"
      />
      <Row>
        <PageHeader>Projects</PageHeader>
        <Badge className="ml-3 self-start">{filteredProjects.length}</Badge>
      </Row>
      <Row className="mb-6 justify-between">
        <ProjectFilters onSort={onSort} sortBy={sortBy} filterByTags={filterByTags} onFilterByTags={onFilterByTags} />
        {/**
         * stars for github when i add gh projects
         * <Row className="ml-3">
         *  <div className="text-sm text-gray-500 mr-1">
         *     Total stars:
         *   </div>
         *   <Stars stars={getTotalGetHubProjectStars(projects)} />
         * </Row>
         *
         */}
      </Row>
      <ProjectsList projects={filteredProjects} />
    </PageLayout>
  );
};

export default ProjectsScreen;
